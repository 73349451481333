import Attachment from './Attachment';
import PropTypes from 'prop-types';

const Attachments = ({ attachments, ...props }) => {
  return (
    <div {...props}>
      {attachments?.map((attachment) => (
        <div className='mb-2' key={attachment.id}>
          <Attachment attachment={attachment} />
        </div>
      ))}
    </div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
};

export default Attachments;
