import { createSlice } from '@reduxjs/toolkit';

export const assignmentsSlice = createSlice({
  name: 'Assignments',
  initialState: {},
  reducers: {
    addAssignments: (state, action) => {
      const assignments = action.payload;
      assignments.forEach((assignment) => {
        state[assignment.id] = assignment;
      });
      return state;
    },
    addAssignment: (state, action) => {
      state[action.payload.id] = action.payload;
      return state;
    },
    updateAssignment: (state, action) => {
      const { id } = action.payload;
      state[id] = { ...state[id], ...action.payload };
      return state;
    },
    addAssignmentAttachment: (state, action) => {
      const { id } = action.payload;
      const currentAssignment = state[id];
      if (currentAssignment.attachments.length === 0) {
        currentAssignment.attachments = [action.payload.attachment];
      } else {
        currentAssignment.attachments = [
          ...currentAssignment.attachments,
          action.payload.attachment,
        ];
      }

      state[id] = currentAssignment;

      return state;
    },
    removeAssignment: (state, action) => {
      const { id } = action.payload;
      delete state[id];
      return state;
    },
  },
});

export const {
  addAssignments,
  addAssignment,
  updateAssignment,
  removeAssignment,
  addAssignmentAttachment,
} = assignmentsSlice.actions;

export default assignmentsSlice.reducer;
