import * as microsoftTeams from '@microsoft/teams-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StorageService from 'services/StorageService';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/reducers/user-reducer';
import { getUserProfile } from 'apis/';

const TeamsAuthenticate = () => {
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const channelId = searchParams.get('channel_id');
  const tenantId = searchParams.get('tenant_id');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getTeamsToken = async () => {
    try {
      return await microsoftTeams.authentication.getAuthToken();
    } catch (error) {
      console.error(error);
      setError('Error getting token:');
    }
  };

  const setup = async () => {
    try {
      await microsoftTeams.app.initialize();
      const teamsToken = await getTeamsToken();
      console.log('teamsToken', teamsToken);
      StorageService.set('token', teamsToken);
      const user = await getUserProfile(channelId);
      dispatch(setUser(user));
      StorageService.setObject('user', user);
      navigate(`/teams/${tenantId}/channels/${channelId}`);
    } catch (error) {
      console.error(error);
      StorageService.remove('token');
      setError('Error occurred during authentication:');
    }
  };

  useEffect(() => {
    setup();
  }, []);

  return (
    <div>
      <h1>
        {error ? (
          <>
            <div>{error}</div>
            <button onClick={setup}>Try again</button>
          </>
        ) : (
          <>Authenticating...</>
        )}
      </h1>
    </div>
  );
};

export default TeamsAuthenticate;
