import { H3 } from '@gonurture/design-system';
import Attachments from 'components/attachments/Attachments';
import StudentSubmission from 'components/student-submission/StudentSubmission';
import { useParams } from 'react-router-dom';
import { useCurrentUserAssignment } from 'store/selectors';

const AssignmentDetails = () => {
  const { userAssignmentId } = useParams();
  const currentUserAssignment = useCurrentUserAssignment(userAssignmentId);

  return (
    <div className='mt-10'>
      <div className='mt-6'>
        <H3>Description</H3>
        <p>{currentUserAssignment?.work.description}</p>
      </div>

      {currentUserAssignment?.work.attachments?.length > 0 && (
        <div className='mt-6'>
          <H3>Attachments</H3>
          <Attachments attachments={currentUserAssignment?.work.attachments} />
        </div>
      )}

      <div className='mt-6'>
        <H3>Learning Objectives</H3>
        {
          <ul className='list-disc px-5'>
            {currentUserAssignment?.work.objectives?.map((objective) => (
              <li key={objective.id}>{objective.name}</li>
            ))}
          </ul>
        }
      </div>

      <div className='mt-5'>
        <H3 className='mt-6 mb-3'>Submissions</H3>
        <StudentSubmission userAssignmentId={userAssignmentId} />
      </div>
    </div>
  );
};

export default AssignmentDetails;
