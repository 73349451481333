import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCurrentAssignment } from 'store/selectors';
import { addAssignment } from 'store/reducers/assignments-reducer';
import { useDispatch } from 'react-redux';
import {
  AssignmentPageHeader,
  Avatar,
  Button,
  H3,
  useToast,
} from '@gonurture/design-system';
import { useErrorHandler } from 'hooks/';
import { setAppError } from 'store/reducers/app-error-reducer';
import Attachments from 'components/attachments/Attachments';
import { assignmentShow, userAssignmentShow } from 'apis';
import UserAssignmentModal from './user-assignment-modal/UserAssignmentModal';
import {
  clearUserAssignments,
  updateUserAssignment,
} from 'store/reducers/user-assignments-reducer';

const AssignmentShow = () => {
  const [userAssignmentLoading, setUserAssignmentLoading] = useState(null);
  const [userAssignmentModalOpened, setUserAssignmentModalOpened] =
    useState(false);
  const [selectedUserAssignment, setSelectedUserAssignment] = useState(null);
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const { channelId, tenantId, assignmentId } = useParams();
  const currentAssignment = useCurrentAssignment(assignmentId);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();

  const fetchAssignment = async () => {
    try {
      setAssignmentLoading(true);
      const assignment = await assignmentShow(channelId, assignmentId);
      dispatch(addAssignment(assignment));
      dispatch(clearUserAssignments()); // clear the userAssignment in the store
      setAssignmentLoading(false);
    } catch (e) {
      setAssignmentLoading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching assignment',
          variant: 'error',
        });
        dispatch(
          setAppError({ message: 'Error occurred when fetching assignment' }),
        );
      });
    }
  };

  const handleEdit = () => {
    navigate(
      `/teams/${tenantId}/channels/${channelId}/assignment/${assignmentId}/edit`,
    );
  };

  const handleSelectUserAssignment = async (userAssignment) => {
    try {
      setUserAssignmentLoading(userAssignment.id);
      const result = await userAssignmentShow(channelId, userAssignment.id);
      dispatch(updateUserAssignment(result));
      setUserAssignmentModalOpened(true);
      setSelectedUserAssignment(userAssignment);
      setUserAssignmentLoading(null);
    } catch (e) {
      setUserAssignmentLoading(null);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred when fetching student work',
          variant: 'destructive',
        });
      });
    }
  };

  useEffect(() => {
    fetchAssignment();
  }, []);

  return (
    <>
      {assignmentLoading && <div>Loading...</div>}

      {!assignmentLoading && (
        <div className='mt-5'>
          <AssignmentPageHeader
            dueAt={currentAssignment?.due_date}
            feedbackPublishedAt={undefined}
            reflectionCompletedAt={undefined}
            studentSubmittedAt={undefined}
            title={currentAssignment?.title}
          />

          <div className='mt-5'>
            <Button onClick={handleEdit}>Edit</Button>
          </div>

          <div className='mt-5'>
            <H3>Description</H3>
            <p>{currentAssignment?.description}</p>
          </div>

          {currentAssignment?.attachments?.length > 0 && (
            <div className='mt-5'>
              <H3>Attachments</H3>
              <Attachments attachments={currentAssignment?.attachments} />
            </div>
          )}

          <div className='mt-5'>
            <H3>Learning Objectives</H3>
            {
              <ul className='list-disc px-5'>
                {currentAssignment?.objectives?.map((objective) => (
                  <li key={objective.id}>{objective.name}</li>
                ))}
              </ul>
            }
          </div>

          <div className='mt-5'>
            <H3>Students</H3>
            <div className='overflow-x-auto'>
              <table className='min-w-full bg-white border border-gray-200'>
                <thead>
                  <tr className='bg-gray-50'>
                    <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                      Avatar
                    </th>
                    <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                      Name
                    </th>
                    <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                      Email
                    </th>
                    <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                      Status
                    </th>
                    <th className='py-2 px-4 border-b border-gray-200 text-left text-sm font-semibold text-gray-700'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentAssignment?.user_works?.map((userWork) => (
                    <tr key={userWork.id}>
                      <td className='py-2 px-4 border-b border-gray-200'>
                        <Avatar
                          src={userWork.user.avatar.url || undefined}
                          width='40'
                          height='40'
                        />
                      </td>
                      <td className='py-2 px-4 border-b border-gray-200'>
                        {userWork.user.display_name}
                      </td>
                      <td className='py-2 px-4 border-b border-gray-200'>
                        {userWork.user.email}
                      </td>
                      <td className='py-2 px-4 border-b border-gray-200'>
                        {userWork.submitted ? 'Submitted' : 'Not Submitted'}
                      </td>
                      <td className='py-2 px-4 border-b border-gray-200'>
                        <Button
                          withLoader={true}
                          disabled={userAssignmentLoading}
                          loading={userAssignmentLoading === userWork.id}
                          loadertext='Loading...'
                          onClick={() => handleSelectUserAssignment(userWork)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <UserAssignmentModal
            defaultUserAssignmentId={selectedUserAssignment?.id}
            opened={userAssignmentModalOpened}
            onClose={() => setUserAssignmentModalOpened(false)}
          />
        </div>
      )}
    </>
  );
};

export default AssignmentShow;
