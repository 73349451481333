import { createRequest } from 'services/ApiService';

export const addAttachment = async (channelId, file) => {
  try {
    return createRequest({
      url: 'attachments',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      payload: file,
      queryParams: { group_id: channelId },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeAttachment = () => {};
