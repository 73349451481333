import { useSelector } from 'react-redux';

export const useUser = () => useSelector((state) => state.user);

export const useClassroom = () =>
  useSelector((state) => {
    return {
      channelId: state.classroom.channelId,
      tenantId: state.classroom.tenantId,
      members: state.classroom.members,
      students: state.classroom.members.filter(
        (member) => member.role === 'student',
      ),
      teachers: state.classroom.members.filter(
        (member) => member.role === 'teacher',
      ),
    };
  });

export const useAppError = () => useSelector((state) => state.appError);

export const useCurrentAssignment = (id) =>
  useSelector((state) => state?.assignments[id]);

export const useAssignments = () =>
  useSelector((state) => Object.values(state?.assignments || {}));

export const useCurrentUserAssignment = (id) =>
  useSelector((state) => state?.userAssignments[id]);

export const useUserAssignments = () =>
  useSelector((state) => Object.values(state?.userAssignments || {}));
