import { useFieldArray } from 'react-hook-form';
import { Button, Input, useToast } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const LearningObjectivesFields = ({ control, formConfig }) => {
  const { register } = formConfig;
  const { toast } = useToast();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'objectives',
  });

  const {
    formState: { errors },
  } = formConfig;

  const onAppend = () => {
    append({ name: '' });
  };

  const onRemove = (index) => {
    if (fields.length === 1) {
      toast({
        variant: 'destructive',
        description: 'You must have at least one objective',
      });
      return;
    }
    remove(index);
  };

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className='mb-3'>
            <Input
              id={`objectives[${index}].name`}
              label={`Objective ${index + 1}`}
              error={errors?.objectives?.[index]?.name?.message}
              {...register(`objectives.${index}.name`)}
            />
            <div
              className='text-red-600 cursor-pointer'
              onClick={() => onRemove(index)}
            >
              Remove
            </div>
          </div>
        );
      })}

      <div>
        <Button onClick={onAppend}>Add Objective</Button>
      </div>
    </>
  );
};

LearningObjectivesFields.propTypes = {
  control: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
};

export default LearningObjectivesFields;
