import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Setup from 'pages/setup/Setup';
import Home from 'pages/home/Home';
import Protected, { AuthenticateUser } from 'pages/protected/Protected';
import Authenticate from 'pages/authenticate/Authenticate';
import AdminConsent from 'pages/admin-consent/AdminConsent';
import AdminConsentRedirect from 'pages/admin-consent/AdminConsentRedirect';
import NotFound from 'pages/not-found/NotFound';
import AssignmentNew from 'pages/assignment/assignment-new/AssignmentNew';
import AssignmentShow from 'pages/assignment/assignment-show/AssignmentShow';
import AssignmentEdit from 'pages/assignment/assignment-edit/AssignmentEdit';
import UserAssignmentShow from './pages/assignment/user-assignment-show/UserAssignmentShow';

const AppRoutes = () => {
  const router = createBrowserRouter([
    {
      path: 'teams',
      element: <Protected />,
      loader: AuthenticateUser,
      children: [
        {
          path: ':tenantId/channels/:channelId',
          children: [
            {
              path: '',
              element: <Home />,
            },
            {
              path: 'assignment/new',
              element: <AssignmentNew />,
            },
            {
              path: 'assignment/:assignmentId',
              element: <AssignmentShow />,
            },
            {
              path: 'assignment/:assignmentId/edit',
              element: <AssignmentEdit />,
            },
            {
              path: 'user-assignment/:userAssignmentId',
              element: <UserAssignmentShow />,
            },
          ],
        },
      ],
    },
    {
      path: 'authenticate',
      element: <Authenticate />,
    },
    {
      path: 'adminconsent',
      children: [
        {
          path: '',
          element: <AdminConsent />,
        },
        {
          path: 'redirect',
          element: <AdminConsentRedirect />,
        },
      ],
    },
    {
      path: 'setup',
      element: <Setup />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRoutes;
