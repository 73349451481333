import { Button, Input, useToast } from '@gonurture/design-system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useErrorHandler } from 'hooks/';
import { useClassroom } from 'store/selectors';
import { addAttachment } from 'apis/';

const FileUploader = ({ attachableParams, onUpload }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const errorHandler = useErrorHandler();
  const { toast } = useToast();
  const { channelId } = useClassroom();

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setUploading(true);
      const form = new FormData();
      form.append('file', file);
      for (let key in attachableParams) {
        form.append(key, attachableParams[key]);
      }
      const attachment = await addAttachment(channelId, form);
      onUpload(attachment);
      setUploading(false);
      toast({
        description: 'Attachment uploaded successfully',
        variant: 'success',
      });
    } catch (e) {
      setUploading(false);
      errorHandler(e, () => {
        toast({
          description: 'Error occurred during attachment upload',
          variant: 'error',
        });
      });
    }
  };

  return (
    <>
      <Input type='file' label='Attachments' onChange={handleChange} />
      <Button
        onClick={handleUpload}
        disabled={!file}
        className='mt-1'
        color='secondary'
        withLoader={true}
        loading={uploading}
        loadingText='Uploading...'
      >
        Upload
      </Button>
    </>
  );
};

FileUploader.defaultProps = {
  onUpload: () => {},
};

FileUploader.propTypes = {
  attachableParams: PropTypes.object.isRequired,
  onUpload: PropTypes.func,
};

export default FileUploader;
