import { object, string, number, date, mixed, array } from 'yup';

const assignmentFormSchema = object({
  title: string().required('Title is required').nullable(),
  description: string().required('Description is required').nullable(),
  due_date: date(),
  grade_display: string().required('Grade display is required').nullable(),
  objectives: array()
    .min(1, 'At least one objective is required')
    .of(
      object({
        _id: number(),
        id: number(),
        name: string().required('Objective Name is required'),
      }),
    ),
  max_points: number().when('grade_display', (grade_display, schema) => {
    if (grade_display !== 'grade_display_rubrics') {
      return schema
        .required('Max points is required')
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(1, 'The minimum allowable mark is 1')
        .nullable();
    }
  }),
  rubrics_json: mixed().when('grade_display', (grade_display, schema) => {
    if (grade_display === 'grade_display_rubrics') {
      return schema.required('Rubrics is required');
    }
  }),
  rubric_columns_attributes: mixed().when(
    'grade_display',
    (grade_display, schema) => {
      if (grade_display === 'grade_display_rubrics') {
        return schema.required('Rubric columns are required');
      }
    },
  ),
});

export default assignmentFormSchema;
