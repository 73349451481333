import { object, string } from 'yup';

const submissionFormSchema = object({
  comment: string().nullable(),
  confidence_level_emoji: string().required(
    'Confidence level emoji is required',
  ),
});

export default submissionFormSchema;
