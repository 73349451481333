import React from 'react';
import { Input } from '@gonurture/design-system';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import PropTypes from 'prop-types';

const CustomDateInput = React.forwardRef(
  ({ label, value, ...otherProps }, ref) => (
    <>
      <Input
        label={label}
        type='date'
        value={value}
        ref={ref}
        {...otherProps}
      />
    </>
  ),
);

CustomDateInput.displayName = 'CustomDateInput';

CustomDateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  inputRef: PropTypes.any,
};

const DateInput = ({ label, ...otherProps }) => (
  <Flatpickr
    onChange={otherProps?.onChange}
    value={otherProps?.value}
    options={{
      enableTime: true,
      enableSeconds: true,
      defaultHour: 23,
      defaultMinute: 59,
      minTime: '00:00',
      maxTime: '23:59',
      minDate: new Date().setDate(new Date().getDate() + 1), // tomorrow
      time_24hr: true,
      allowInput: true,
      altInput: true,
      altFormat: 'F j, Y H:i',
      ...otherProps,
    }}
    render={({ value, ...props }, ref) => {
      return (
        <CustomDateInput value={value} label={label} ref={ref} {...props} />
      );
    }}
  />
);

DateInput.propTypes = {
  label: PropTypes.string,
};

export default DateInput;
