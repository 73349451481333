import { BaseFile } from '@gonurture/design-system';
import PropTypes from 'prop-types';

const Attachment = ({ attachment }) => {
  return (
    <BaseFile
      filename={attachment.filename}
      filesize={attachment.filesize}
      filetype={attachment.filetype}
    />
  );
};

Attachment.propTypes = {
  attachment: PropTypes.shape({
    filename: PropTypes.string,
    filesize: PropTypes.string,
    filetype: PropTypes.string,
  }).isRequired,
};

export default Attachment;
