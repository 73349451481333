import { H2 } from '@gonurture/design-system';
import AssignmentForm from 'components/assignment-form/AssignmentForm';

const AssignmentNew = () => {
  return (
    <div>
      <H2>Create Assignment</H2>
      <AssignmentForm />
    </div>
  );
};

export default AssignmentNew;
