import { H2 } from '@gonurture/design-system';
import { useParams } from 'react-router-dom';
import AssignmentForm from 'components/assignment-form/AssignmentForm';

const AssignmentEdit = () => {
  const { assignmentId } = useParams();

  return (
    <div>
      <H2>Assignment Edit</H2>
      <AssignmentForm
        context='AssignmentEdit'
        defaultAssignmentId={assignmentId}
      />
    </div>
  );
};

export default AssignmentEdit;
