import StorageService from 'services/StorageService';
import { Avatar, Button } from '@gonurture/design-system';
import { useNavigate } from 'react-router-dom';
import AssignmentList from './AssignmentList';
import UserAssignmentList from './UserAssignmentList';

const Home = () => {
  const user = StorageService.getObject('user');
  const navigate = useNavigate();

  const createAssignmentPage = () => {
    navigate('assignment/new');
  };

  return (
    <div>
      <div className='mb-4'>
        <h1>User</h1>
        <p>
          <strong>Name:</strong> {user.display_name}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <div>Avatar</div>
          <Avatar src={user.avatar_url} width='100' height='100' />
        </p>
      </div>
      {user.role === 'teacher' && (
        <>
          <div>
            <Button onClick={createAssignmentPage}>Create Assignment</Button>
          </div>
          <div className='mt-5'>
            <AssignmentList />
          </div>
        </>
      )}

      {user.role === 'student' && (
        <div className='mt-5'>
          <UserAssignmentList />
        </div>
      )}
    </div>
  );
};

export default Home;
